import { t, setLanguage, getLanguage } from '../utils/ReactSwitchLangWrapper';
import { ExternalLink } from './Link';
import Button from './Button';
import { useRouter } from './Router';
import { events, logAmpEvent } from '../utils/Amplitude';
import style from '../styles/Footer.module.scss';
import { LANGUAGES } from '../utils/Constants';
import { ItalicizeInterac } from './ReplaceTextWithElement';

function FooterLink({ langKey, href, ariaLabel, className }) {
  return (
    <ExternalLink
      href={href}
      ampEvent={events.USER_CLICKED_FOOTER_LINK}
      ampEventProps={{ Link: t(langKey, null, LANGUAGES.EN.code) }}
      text={t(langKey)}
      aria-label={ariaLabel}
      className={className}
    />
  );
}

export default function Footer({ showLanguageSwitcher }) {
  const router = useRouter();
  const ar = router.pathname.split('[lang]');
  const curPath = ar[ar.length - 1];

  function languageToFullButton() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.label : LANGUAGES.FR.label;
  }

  function languageToCode() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.code : LANGUAGES.FR.code;
  }

  function selectLanguage(lang) {
    if (getLanguage() === lang) return;
    logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: lang === LANGUAGES.FR.code ? 'French' : 'English' });
    document.documentElement.lang = lang;
    if (router.pathname === '/404') {
      setLanguage(lang);
      router.pushStatic('/404', router.asPath);
    } else {
      router.pushStatic(`/${lang}${curPath}`);
    }
  }

  return (
    <footer id="footer" className={style.footer}>

      <div className={`${getLanguage() === LANGUAGES.FR.code ? style.miniFooterFrench : style.miniFooter}`}>
        <div className={style.footerLinks}>
          <FooterLink langKey="Footer_PrivacyPolicy" href={t('Link_PSI_Partner_Portal_Web_PrivacyPolicy')} ariaLabel={t('LinkDescription_PrivacyPolicy')} className={style.miniFooterLink} />
          {showLanguageSwitcher && (
            <Button
              linkBtn
              text={languageToFullButton()}
              ariaLabel={t('Language_Switch_To_Link', null, getLanguage())}
              className={style.miniFooterLink}
              onClick={() => {
                selectLanguage(getLanguage() === LANGUAGES.FR.code ?
                  LANGUAGES.EN.code :
                  LANGUAGES.FR.code);
              }}
              lang={languageToCode()}
            />
          )}
        </div>
        <p className={style.copyrightText}><ItalicizeInterac text={t('Footer_Copyright_Text3')} /></p>

      </div>
    </footer>
  );
}
