import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/legacy/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { t, translate } from '../utils/ReactSwitchLangWrapper';
import Link from './Link';
import { useCognito } from '../utils/Cognito';
import { events, logAmpEvent } from '../utils/Amplitude';
import logo from '../public/assets/PSILogo.png';
import style from '../styles/NavBar.module.scss';
import { setScreenReaderAlert } from '../utils/Accessibility';
import { useRouter } from './Router';
import { isLoadhubUserGroup, isShoppersUserGroup } from '../utils/UserGroup';

function HelpCenterButton() {
  return (
    <button
      className={style.HelpCentreButton}
      type="button"
      onClick={() => {
        logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, {
          Link: t('Footer_HelpCentre', null, 'en'),
        });
        const widgetButton = document.getElementById('widget-button');
        widgetButton.click();
      }}
      aria-label={t('ButtonDescription_HelpCentre_Widget')}
    >
      {t('Footer_HelpCentre')}
    </button>
  );
}

function NavBar({ authPage }) {
  const showDevEnv = ['local_test', 'local_prod', 'prod_dev'].includes(process.env.NEXT_PUBLIC_RUNTIME_ENV);
  const showRCEnv = ['prod_rc'].includes(process.env.NEXT_PUBLIC_RUNTIME_ENV);

  const [showHamburgerDropdown, toggleHamburgerDropdown] = useState(false);
  const [showTextTimeout, setShowTextTimeout] = useState(null);
  const session = useSelector((state) => state.auth.session);
  const cognito = useCognito();
  const router = useRouter();
  const path = router.pathname;

  const routes = {
    Home: '/home',
    ReportsAndRefunds: '/reports-and-refunds',
    SignUp: '/login',
  };

  const isCurrentPage = (page) => {
    const route = path.split('/')[2];
    return route === page.substring(1);
  };

  useEffect(() => {
    if (!showHamburgerDropdown) {
      if (showTextTimeout) return;
      setShowTextTimeout(
        setTimeout(() => {
          setShowTextTimeout(null);
        }, 250)
      );
    } else if (showTextTimeout) {
      clearTimeout(showTextTimeout);
      setShowTextTimeout(null);
    }
  }, [showHamburgerDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <nav
      id={style.nav}
    >
      <div
        className={`${style.navContent} ${
          showHamburgerDropdown ? style.expanded : ''
        } maxWidth16`}
        id="nav-bar"
      >
        <div className={style.row}>
          <button
            id={style.Hamburger}
            className={style.corners}
            type="button"
            title={t('ButtonDescription_HamburgerMenu')}
            aria-label={t('NavBar_HamburgerMenuBtn_Description')}
            onClick={() => {
              logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, {
                Link: 'Hamburger Icon',
                Type: showHamburgerDropdown ? 'Hide' : 'Show',
              });
              toggleHamburgerDropdown(!showHamburgerDropdown);
            }}
            data-action="disclosure"
            aria-controls="hamburger-dropdown"
            aria-expanded={showHamburgerDropdown ? 'true' : 'false'}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={style.logoContainer}>
            <Link
              href="/"
              className={style.logo}
              ampEvent={events.USER_CLICKED_NAVBAR_LINK}
              ampEventProps={{ Link: 'Logo' }}
              onClick={() => toggleHamburgerDropdown(false)}
            >
              <Image
                src={logo}
                alt={t('ImageDescription_PaymentSourceLogo')}
                layout="fill"
                objectFit="contain"
                unoptimized
              />
            </Link>
            <div>
              <p className={`${style.text} ${style.show}`}>
                {t('NavBar_Partner_Portal')}
              </p>
              {showRCEnv && <p className={`${style.env}`}>Release Candidate</p>}
              {showDevEnv && <p className={`${style.env}`}>Development</p>}
            </div>
          </div>
          <div className={style.corners} />
          <div id={style.Links}>
            {/* the extra div here is necessary for collapsible css to work properly */}
            <div>
              {session ? (
                <>
                  {isShoppersUserGroup() || (
                  <>
                    {isLoadhubUserGroup() || (
                    <Link
                      href={routes.Home}
                      ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                      ampEventProps={{ Link: t('NavBar_Home', null, 'en') }}
                      text={t('NavBar_Home')}
                      aria-label={t('LinkDescription_Home')}
                      className={isCurrentPage(routes.Home) ? style.currentPage : ''}
                    />
                    )}

                    <Link
                      href={routes.ReportsAndRefunds}
                      ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                      ampEventProps={{ Link: t('NavBar_Reports', null, 'en') }}
                      text={t('NavBar_Reports')}
                      aria-label={t('LinkDescription_Reports')}
                      className={isCurrentPage(routes.ReportsAndRefunds) ? style.currentPage : ''}
                    />
                    <HelpCenterButton />
                  </>
                  )}
                  <button
                    id={style.LogoutBtn}
                    type="button"
                    onClick={() => {
                      logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, {
                        Link: t('Navbar_Lbl_Logout', null, 'en'),
                      });
                      if (path === '/[lang]/loading' || path === '/[lang]/home' ||
                      path === '/[lang]/application/before-you-start' ||
                      path === '/[lang]/application/business-information' ||
                      path === '/[lang]/application/contact-information' ||
                      path === '/[lang]/application/agreement' ||
                      path === '/[lang]/application/preferences' ||
                      path === '/[lang]/platform-settings/xero' ||
                      path === '/[lang]/maintenance' ||
                      path === '/[lang]/reports-and-refunds' ||
                      path === '/[lang]/reports-and-refunds/select-partner') {
                        router.push({ pathname: '/logout', query: { to: 'login' } });
                        return;
                      }
                      toggleHamburgerDropdown(false);
                      cognito.signOut().then(() => {
                        logAmpEvent(events.USER_LOGGED_OUT);
                        setScreenReaderAlert(t('ScreenReaderAlert_LogoutSuccess'));
                      });
                    }}
                  >
                    {t('Navbar_Lbl_Logout')}
                  </button>
                </>
              ) : (
                <>
                  <HelpCenterButton />
                  {authPage || (
                    <Link
                      id={style.SignUpBtn}
                      href={routes.SignUp}
                      ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                      ampEventProps={{ Link: t('NavBar_SignUp', null, 'en') }}
                      text={t('NavBar_SignUp')}
                      onClick={() => toggleHamburgerDropdown(false)}
                      aria-label={t('LinkDescription_SignUp')}
                      className={isCurrentPage(routes.SignUp) ? style.currentPage : ''}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default translate(NavBar);
